import { AxiosError, AxiosPromise } from 'axios';
import { isNetworkOrIdempotentRequestError } from 'axios-retry';
import axios, { AxiosRetryRequestConfig } from '@/api/service';
import URLs from '@/api/service/urls';
import * as Projects from '@/types/projects.types';
import { PaginatedResponseBodyProjects } from '@/types/api.types';
import { Environments } from '@/constants';
import { First } from '@/types/util.types';
import {ProjectTeam} from '@/types/teams.types';

function isValidProjectEnvId(id: number): boolean {
  return !isNaN(id) && id > -1;
}

export { Projects };
export type ProjectsAPIResponse = PaginatedResponseBodyProjects<Projects.ProjectListProject>;
export type ConnectingProjectsAPIResponse = PaginatedResponseBodyProjects<Projects.ConnectingProjectList>;
export interface ProjectEnvironmentPromotion {
  projectEnvironmentId: number;
  environmentId: number;
  environmentName: string;
  statusId: number;
  statusName: string;
}

export function getProjects(): AxiosPromise<ProjectsAPIResponse> {
  return axios.get<ProjectsAPIResponse>(URLs.Projects);
}

export function getAllProjects(): AxiosPromise<ProjectsAPIResponse> {
  return axios.get<ProjectsAPIResponse>(URLs.AllProjects);
}

export function manageThisProject(projectId: number, isEnabled: boolean): boolean | any {
  return axios.post(URLs.ManageThisProject, {}, { params: { projectId, isEnabled } });
}

export function getConnectingServiceProjects(data: {serviceId: number}): AxiosPromise<ConnectingProjectsAPIResponse> {
  return axios.post(URLs.ConnectingServiceProjects, data);
}

export function createProject(
  data: Projects.CreateProjectRequestBody,
): AxiosPromise<{ projectId: number }> {
  return axios.post(URLs.Projects, data);
}

export function getCiBusinessOwner(ci:string): AxiosPromise<{ mudId: string, email:string, fullName: string}> {
  return axios.get<{ mudId: string, email:string, fullName: string}>(URLs.GetCIBusinessOwner, {params: {ci}});
}

export function getInvictiScanReport(scanId:string): AxiosPromise<any> {
  return axios.get<any>(URLs.DownloadInvictiScanReport, {params: {scanId}, responseType: 'blob'} );
}

export function getServiceApproverList(serviceId:number): AxiosPromise<{ mudId: string, email:string, fullName: string}> {
  return axios.get<{ mudId: string, email:string, fullName: string}>(URLs.GetServiceApproverList, {params: {serviceId}});
}

export function promoteProjectToQA({
  projectEnvId,
  body,
}: {
  projectEnvId: number | string;
  body: Projects.APIPromotePayload;
}): AxiosPromise<ProjectEnvironmentPromotion> {
  return axios.post(URLs.ProjectPromoteToQA, body, { params: { projectEnvId } });
}

export function promoteProjectToProd({
  projectEnvId,
  query,
}: {
  projectEnvId: string | number;
  query: Projects.ProdPromoteQuery;
}): AxiosPromise<ProjectEnvironmentPromotion> {
  return axios.post(URLs.ProjectPromoteToProd, query, { params: { projectEnvId } });
}

export function promoteRequiresApproval({
  projectEnvId,
}: {
  projectEnvId: string | number;
}): AxiosPromise<{ registrationId: number } | ''> {
  return axios.get(URLs.ProjectPromoteRequiresApproval, {
    params: { projectEnvId },
  });
}

export function promoteProjectEnvironment(
  payload:
    | (First<Parameters<typeof promoteProjectToQA>> & { srcEnv: Environments.Dev })
    | (First<Parameters<typeof promoteProjectToProd>> & { srcEnv: Environments.Qa }),
): AxiosPromise<ProjectEnvironmentPromotion> {
  if (payload.srcEnv === Environments.Dev) {
    return promoteProjectToQA(payload);
  } else {
    return promoteProjectToProd(payload);
  }
}

export function getProjectDetails(projectId: number | string): AxiosPromise<Projects.Project> {
  return axios.get<Projects.Project>(URLs.Project, { params: { projectId } });
}

export function getServiceNowIds(query: string): AxiosPromise<Projects.PandoraApiResponse[]> {
  return axios.get(URLs.PandoraIDs, { params: { query } });
}

export function getProjectPermissions(
  projectId: number | string,
): AxiosPromise<Projects.ProjectPermissions> {
  return axios.get<Projects.ProjectPermissions>(URLs.ProjectPermissions, {
    params: { projectId },
  });
}

/**
 * this one is janky right after a project is created
 * something with the deets not being fully into the keyvault service i think
 */
export function getProjectEnv(params: {
  projectId: Projects.Project['projectId'];
  projectEnvId: Projects.ProjectEnvironment['projectEnvironmentId'];
}): AxiosPromise<Projects.ProjectEnvironmentDetails> {
  return axios.get(URLs.ProjectEnvironment, {
    params,
    'axios-retry': {
      retries: 3,
      retryDelay: (): number => 1000,
      retryCondition(err: AxiosError): boolean {
        return (
          isNetworkOrIdempotentRequestError(err) || (err.response || { status: 0 }).status === 404
        );
      },
    },
  } as AxiosRetryRequestConfig);
}

export async function projectsByServiceId(
  serviceId: number | string,
): Promise<Projects.ServiceConnectedProjectsResponse> {
  return axios
    .get(URLs.ServiceConnectedProjects, {
      params: {
        serviceId,
      },
    })
    .then(r => r.data);
}

export function getProjectEnvStatus(params: {
  projectId: Projects.Project['projectId'];
  projectEnvId: Projects.ProjectEnvironment['projectEnvironmentId'];
}): AxiosPromise<Projects.ProjectEnvironmentStatus> {
  if (isValidProjectEnvId(params.projectEnvId)) {
    return axios.get(URLs.ProjectEnvironmentStatus, {
      params,
    });
  } else {
    throw new Error();
  }
}

export function getProjectEnvAuth(params: {
  projectId: Projects.Project['projectId'];
  projectEnvId: Projects.ProjectEnvironment['projectEnvironmentId'];
}): AxiosPromise<Projects.ProjectEnvironmentAuth> {
  if (isValidProjectEnvId(params.projectEnvId)) {
    return axios.get(URLs.ProjectEnvironmentAuth, {
      params,
    });
  } else {
    throw new Error();
  }
}

export function getProjectEnvServices(params: {
  projectId: Projects.Project['projectId'];
  projectEnvId: Projects.ProjectEnvironment['projectEnvironmentId'];
}): AxiosPromise<Projects.ProjectEnvironmentServices> {
  if (isValidProjectEnvId(params.projectEnvId)) {
    return axios.get(URLs.ProjectEnvironmentServices, {
      params,
    });
  } else {
    throw new Error();
  }
}

export function getProjectEnvRegistrations(params: {
  projectId: Projects.Project['projectId'];
  projectEnvId: Projects.ProjectEnvironment['projectEnvironmentId'];
}): AxiosPromise<Projects.ProjectEnvironmentServices> {
  if (isValidProjectEnvId(params.projectEnvId)) {
    return axios.get(URLs.ProjectServices, {
      params,
    });
  } else {
    throw new Error();
  }
}

export async function getProjectEnvConnections(params: {
  projectEnvId: Projects.ProjectEnvironment['projectEnvironmentId'];
}): Promise<{ data: Projects.ProjectEnvironmentConnections }> {
  const res = await axios.get<Projects.ProjectEnvironmentConnectionsDto>(
    URLs.ProjectEnvironmentConnections,
    {
      params,
    },
  );

  const data = res.data.reduce((acc: Projects.ProjectEnvironmentConnections, obj) => {
    return { ...acc, ...obj };
  }, {} as Projects.ProjectEnvironmentConnections);

  return { data };
}

export async function getProjectEnvRpa(params: {
  projectId: number;
}): Promise<{ data: { bots: Projects.ProjectBot[] } }> {
  const res = await axios.get(URLs.GetProjectBots, { params });
  return { data: { bots: res.data } };
}

export function updateProjectPermissions({
  projectId,
  users,
  teams,
}: {
  projectId: string | number;
  users: Omit<Projects.ProjectUser, 'roleName'>[];
  teams: ProjectTeam[];
}): AxiosPromise<unknown> {
  const teamUpdates: ProjectTeam[] = [];
  teams.forEach( (ref:ProjectTeam) => {
    teamUpdates.push({ roleId: ref.roleId,
      teamId: ref.teamId,
      teamName: ref.teamName,
      teamMembers: [],
      teamDescription: ref.teamDescription });
  });

  return axios.put(URLs.ProjectPermissions, { users, teams: teamUpdates }, { params: { projectId } });
}

export function updateProjectDetails({
  projectId,
  projectName,
  projectDescription,
  businessUnitId,
  businessSubUnit,
  budgetingSource,
}: {
  projectId: string | number;
  projectName: string;
  projectDescription: string;
  businessUnitId?: string | number;
  businessSubUnit?: string;
  budgetingSource?:Projects.BudgetingSource | string;
}): AxiosPromise<unknown> {
  return axios.put(
    URLs.Project,
    { projectName, projectDescription, businessUnitId, businessSubUnit, budgetingSource },
    { params: { projectId } },
  );
}

export function deleteProject(projectId: string | number): AxiosPromise<unknown> {
  return axios.delete(URLs.Project, { params: { projectId } }).catch(err => err);
}

export function addServiceToProject({
  projectId,
  projectEnvId,
  serviceEnvId,
  oauthType,
}: {
  projectId: string | number;
  projectEnvId: number;
  serviceEnvId: number;
  oauthType: 'AD_OAUTH' | 'PING_OAUTH';
}): AxiosPromise<unknown> {
  return axios.post(
    URLs.ProjectEnvironmentService,
    {},
    {
      params: { projectId, projectEnvId, serviceEnvId, oauthType },
    },
  );
}

export function removeServiceFromProject({
  projectId,
  projectEnvId,
  serviceEnvId,
}: {
  projectId: string | number;
  projectEnvId: number;
  serviceEnvId: number;
}): AxiosPromise<unknown> {
  return axios.delete(URLs.ProjectEnvironmentServiceDelete, {
    params: { projectId, projectEnvId, serviceEnvId },
  });
}

export interface UpdateCallbackUrlsResponse {
  projectEnvironmentConnectionId: number;
  projectEnvironmentId: number;
  connectionDetails: {
    callbackUrls: string[];
    clientId: string;
    oAuth: string;
  };
}

export function updateCallbackUrls(
  projectEnvId: string | number,
  urls: { urls: string[] },
): AxiosPromise<UpdateCallbackUrlsResponse> {
  return axios.put(URLs.ProjectEnvironmentCallbackUrls, urls, { params: { projectEnvId } });
}

export function addBotToProject(
  projectId: string,
  botDetails: Projects.AddProjectBot,
): AxiosPromise<number> {
  return axios.post(URLs.AddBotToProject, botDetails, { params: { projectId } });
}

export function addBotToProjectV2(
  projectId: string,
  botDetails: any,
): AxiosPromise<number> {
  return axios.post(URLs.AddBotToProjectV2, botDetails, { params: { projectId } });
}

export function addBotDeployment(
  botRegistrationId: number,
  botDetails: any,
): AxiosPromise<number> {
  return axios.post(URLs.AddBotDeployment, botDetails, { params: { botRegistrationId } });
}

export function addApiEnvToProject(
  projectId: string | number,
  oauthType: 'AD_OAUTH' | 'PING_OAUTH',
): AxiosPromise<unknown> {
  return axios.post(URLs.ProjectApiEnvironment, { oauthType }, { params: { projectId } });
}

export function addCicdToProject(
  projectId: string | number,
  data: Projects.CreateProjectRequestBody,
): AxiosPromise<{ projectId: number }> {
  return axios.post(URLs.ProjectCicdEnvironment, data, { params: { projectId } });
}

export function updateBotDetails(
  projectId: string,
  botRegistrationId: string | number,
  botDetails: Projects.UpdateProjectBot,
): AxiosPromise<void> {
  return axios.put(URLs.UpdateBotDetails, botDetails, { params: { projectId, botRegistrationId } });
}

export function updateBotAndListingDetails(
  projectId: string,
  botRegistrationId: string | number,
  botDetails: Projects.UpdateProjectBot,
): AxiosPromise<void> {
  return axios.put(URLs.UpdateBotAndListingDetails, botDetails, { params: { projectId, botRegistrationId } });
}

export function removeBotFromProject(
  projectId: number,
  botRegistrationId: number,
  listingId: number,
): AxiosPromise<boolean> {
  return axios.delete(URLs.RemoveProjectBot, { params: { projectId, botRegistrationId, listingId } });
}

export function removeBotDeploymentFromProject(
  projectId: number,
  botDeploymentId: number,
): AxiosPromise<boolean> {
  return axios.delete(URLs.RemoveProjectBotDeployment, { params: { projectId, botDeploymentId } });
}

export function getConfigurationIdName(id: string): AxiosPromise<Projects.ConfigurationName> {
  return axios.get(URLs.ConfigurationName, {
    params: { id },
  });
}

export function addNewSecret(params: {envId: number, ssoSettingId: string }, description: string): AxiosPromise<unknown> {
  return axios.post(URLs.ProjectClientSecret,{ description }, { params });
}

export function addNewApiKey(params: {envId: number }, apiKeyDescription: string): AxiosPromise<unknown> {
  return axios.post(URLs.ProjectApiKey, { name: apiKeyDescription }, { params });
}

export function deleteClientSecret(params: {envId: number, ssoSettingId: string, clientSecretId: string }): AxiosPromise<unknown> {
  return axios.delete(URLs.ProjectClientSecretDelete, { params });
}

export function deleteApiKey(params: {envId: number, apiKeyId: string }): AxiosPromise<unknown> {
  return axios.delete(URLs.ProjectApiKeyDelete, { params });
}

export function resetClientSecret(params: {envId: number, ssoSettingId: string}): AxiosPromise<unknown> {
  return axios.put(URLs.ProjectClientSecretReset, {}, { params });
}

export function modifySecretCredential(params: {envId: number}, clientSecretDto:{clientId: string, secrets: string | Projects.secretKeyValPair[]}) {
  return axios.put(URLs.ProjectClientSecretUpdate, clientSecretDto, {params});
}

export function triggerAPISecurityScan(params: {serviceId: number, projectEnvId: number}) {
  return axios.post(URLs.ProjectEnvironmentServiceSecurityScan, {}, {params});
}

export function populateConnectedServices(params: { envId: number }) {
  return axios.get(URLs.ProjectEnvironmentPopulateConnectedServices, { params });
}

export function checkPluginsSupport(plugins: string[]):AxiosPromise<number> {
  return axios.post(URLs.ContainsUnsupportedPlugin, {plugins})
}

export function getBudgetingSourceList(query: string): AxiosPromise<Projects.BudgetingSourceApiResponse[]> {
  return axios.get(URLs.GetBudgetingSourceList, { params: { query } });
}