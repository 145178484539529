
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaseUser } from '@/types/users.types';
import UserCircle from '@/components/UserCircle.vue';

@Component({
  components: {
    UserCircle,
  },
})
export default class UserList extends Vue {
  @Prop(Array) readonly users!: BaseUser[];
  @Prop(Boolean) readonly truncate!: boolean;
  @Prop(Boolean) readonly expandable!: boolean;
  @Prop({ type: Number, default: 5 }) readonly limit!: number;
  @Prop(Boolean) usersDetailsList!: boolean;
  @Prop({ type: String, default: 'below' }) readonly tooltipPosition!:
    | 'below'
    | 'after'
    | 'before'
    | 'above';
  public windowWidth: any = window.innerWidth;

  private open = false;

  toggle() {
    if (!this.expandable) {
      return;
    }
    this.open = !this.open;
  }

  get expandUserInfo() {
    return this.left > 0 ? `+${this.left}` : '–';
  }

  get showToggle() {
    if (!this.truncate || !this.users) {
      return false;
    }

    return this.users.length > this.limit;
  }

  get left(): number {
    return this.users ? this.users.length - this.list.length : 0;
  }

  get list(): BaseUser[] {
    if (this.truncate) {
      if (this.open) {
        return this.users;
      }

      return this.users ? this.users.slice(0, this.limit) : [];
    }

    return this.users || [];
  }
}
