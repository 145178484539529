
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { Promised } from 'vue-promised';
import { RouteNames } from '@/constants';
import MaxWidth from '@/components/MaxWidth.vue';
import { projectsByServiceId } from '@/api/projects.api';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import UserCircle from '@/components/UserCircle.vue';
import UserList from '@/components/UserList.vue';
import { ServiceConnectedProject, ServiceConnectedProjectsResponse } from '@/types/projects.types';

@Component({
  components: {
    FullScreenForm,
    GButton,
    UserList,
    UserCircle,
    MaxWidth,
    Promised,
  },
})
export default class ProjectsConnectedTableView extends Vue {
  @Prop(String) serviceId!: string;
  @Prop(String) envId!: string;
  @Prop(String) projectId!: string;

  prom: Promise<ServiceConnectedProjectsResponse> = Promise.resolve({
    projects: [],
    registrationName: '',
  });
  noProjects = true;

  get projectRoute(): RawLocation {
    return {
      name: RouteNames.ProjectEnvDetails,
      params: { id: this.projectId, env: this.envId, section: 'registrations' },
    };
  }

  fetchProjects() {
    this.prom = projectsByServiceId(this.serviceId).then(data => {
      this.noProjects = data.projects.length === 0;
      return data;
    });
    return this.prom;
  }
  created() {
    this.fetchProjects();
  }

  async copyEmails() {
    const { projects } = await this.prom;
    const emails = [...new Set(projects.flatMap(p => p.owners.map(o => o.email)))].join(';');
    await navigator.clipboard.writeText(emails);
  }
}
